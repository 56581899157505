<template>
  <div class="legal">
    <div class="background"></div>
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="9">
          <img class="hidden-sm-and-down" src="/static/img/common/privacy_en.png?v=20221027">
          <img class="hidden-md-and-up" src="/static/img/common/privacy_mobile_en.png?v=20221027">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
}

</script>
<style lang="scss" scoped>
.legal{
  position: relative; 
  .background{
    position: fixed;
    display: block;
    top:0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('/static/img/common/policyBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
  }
  .content_style{
    z-index: 3;
  }
  img{
    width:100%;
    height: auto;
  }
  .titleName {
    font-size: 50px;
    color: #ffffff; 
    font-weight: 900;
    text-indent: 0em;
  }
  h2 {
    color: #ffffff; 
    font-weight: 700;
    margin: 60px 0 30px 0;
    font-size: 32px;
    text-indent: 0;
  }
  p {
    color: #ffffff; 
    // text-indent: 1em;
    font-weight: 500;
  }
}
</style>